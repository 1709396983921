"use client"
import { FC, ReactNode, useEffect, useState, useRef } from "react"

import styles from "./Animated.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Animated")

export type AnimatedProps = {
  className?: string
  disabled?: boolean
  animation?: "bottom-top" | "top-bottom" | "left-right" | "right-left"
  delay?: number
  speed?: "fast" | "slow"
  children?: ReactNode
  onAnimate?: (visible: boolean) => void
}

export const Animated: FC<AnimatedProps> = ({
  className,
  disabled,
  delay = 0,
  speed,
  animation,
  children,
  onAnimate,
  ...props
}) => {
  const [isVisible, setVisible] = useState(false)
  const domRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const delayTimeoutId = setTimeout(() => {
          onAnimate?.(entry.isIntersecting)
          setVisible(entry.isIntersecting)
        }, delay)

        return () => clearTimeout(delayTimeoutId)
      })
    })
    observer.observe(domRef.current as Element)
  }, [delay, onAnimate])

  return (
    <div
      ref={domRef}
      className={bem(
        undefined,
        {
          "is-visible": !!isVisible,
          "is-disabled": !!disabled,
          [`has-${speed}-speed`]: !!speed,
          [`animate-${animation}`]: !!animation,
        },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

import { gql } from "@apollo/client"

export const ContentAreaFragment = gql`
  fragment ContentAreaFragment on ComponentSectionsContentArea {
    contentAreaTitle: title
    titleOptions {
      type
      size
      variant
      align
    }
    subTitle
    subTitleOptions {
      type
      size
      variant
      align
    }
    contentAreaImage: image {
      data {
        attributes {
          alternativeText
          url
          caption
        }
      }
    }
    imageDark {
      data {
        attributes {
          alternativeText
          url
          caption
        }
      }
    }
    content
    contentOptions {
      align
    }
  }
`

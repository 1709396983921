import { gql } from "@apollo/client"

export const SEOFragment = gql`
  fragment SEOFragment on ComponentSharedSeo {
    metaTitle
    metaDescription
    metaImage {
      data {
        attributes {
          url
        }
      }
    }
    metaSocial {
      socialNetwork
      title
      description
      image {
        data {
          attributes {
            width
            height
            url
            alternativeText
          }
        }
      }
    }
    keywords
    canonicalURL
  }
`

import { gql } from "@apollo/client"

import { ContentAreaFragment } from "../shared/fragments/contentArea"
import { SEOFragment } from "../shared/fragments/seo"
import { TableFragment } from "../shared/fragments/table"

import { QuoteFragment } from "./fragments/quote"

export const GET_CATEGORIES = gql`
  query categories($locale: I18NLocaleCode) {
    categories(locale: $locale) {
      data {
        attributes {
          slug
        }
      }
    }
  }
`

export const GET_POSTS_BY_CATEGORY = gql`
  query PostsByCategory(
    $locale: I18NLocaleCode
    $categorySorting: [String] = ["name:asc"]
    $articlesSorting: [String] = ["publishedAt:desc"]
    $articlesPagination: PaginationArg = { limit: 3 }
  ) {
    categories(
      locale: $locale
      sort: $categorySorting
      filters: { articles: { id: { ne: null } } }
    ) {
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
      data {
        id
        attributes {
          name
          slug
          description
          articles(sort: $articlesSorting, pagination: $articlesPagination) {
            data {
              id
              attributes {
                title
                description
                slug
                readCount
                cover {
                  data {
                    attributes {
                      alternativeText
                      url
                      caption
                    }
                  }
                }
                publishedAt
              }
            }
          }
        }
      }
    }
  }
`

export const GET_POSTS_BY_CATEGORY_SLUG = gql`
  query PostsByCategorySlug(
    $locale: I18NLocaleCode
    $slug: String!
    $pagination: PaginationArg!
  ) {
    categories(locale: $locale, filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          name
          description
        }
      }
    }
    articles(
      locale: $locale
      pagination: $pagination
      filters: { category: { slug: { eq: $slug } } }
      sort: ["publishedAt:desc"]
    ) {
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
      data {
        id
        attributes {
          title
          description
          slug
          readCount
          category {
            data {
              attributes {
                name
              }
            }
          }
          authorsBio {
            data {
              attributes {
                name
                avatar {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
          cover {
            data {
              attributes {
                alternativeText
                url
                caption
              }
            }
          }
          publishedAt
        }
      }
    }
  }
`

export const GET_POST_META_DATA_BY_SLUG = gql`
  query PostMetaDataBySlug(
    $locale: I18NLocaleCode
    $slug: String!
    $categorySlug: String!
  ) {
    articles(
      locale: $locale
      filters: {
        slug: { eq: $slug }
        category: { slug: { eq: $categorySlug } }
      }
    ) {
      data {
        attributes {
          seo {
            ...SEOFragment
          }
        }
      }
    }
  }
  ${SEOFragment}
`

export const GET_POST_BY_SLUG = gql`
  query PostBySlug(
    $locale: I18NLocaleCode
    $slug: String!
    $categorySlug: String!
  ) {
    articles(
      locale: $locale
      filters: {
        slug: { eq: $slug }
        category: { slug: { eq: $categorySlug } }
      }
    ) {
      data {
        id
        attributes {
          title
          description
          slug
          readCount
          authorsBio {
            data {
              attributes {
                name
                avatar {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              attributes {
                name
                slug
              }
            }
          }
          cover {
            data {
              attributes {
                alternativeText
                url
                caption
              }
            }
          }
          blocks {
            ... on ComponentSectionsContentArea {
              ...ContentAreaFragment
            }
            ... on ComponentSectionsQuote {
              ...QuoteFragment
            }
            ... on ComponentSectionsTable {
              ...TableFragment
            }
          }
          publishedAt
          updatedAt
        }
      }
    }
  }
  ${ContentAreaFragment}
  ${QuoteFragment}
  ${TableFragment}
`

export const GET_LATEST_POSTS = gql`
  query LatestPosts(
    $locale: I18NLocaleCode
    $sorting: [String] = ["publishedAt:desc"]
    $pagination: PaginationArg = { limit: 3 }
  ) {
    articles(locale: $locale, sort: $sorting, pagination: $pagination) {
      data {
        id
        attributes {
          title
          description
          slug
          readCount
          category {
            data {
              attributes {
                name
                slug
              }
            }
          }
          cover {
            data {
              attributes {
                alternativeText
                url
                caption
              }
            }
          }
          publishedAt
        }
      }
    }
  }
`

export const GET_POST_SIDEBAR = gql`
  query PostSidebar(
    $locale: I18NLocaleCode
    $mostPopularFilter: ArticleFiltersInput!
    $lastReadFilter: ArticleFiltersInput!
    $sorting: [String] = ["publishedAt:desc", "readCount:desc"]
    $pagination: PaginationArg = { limit: 3 }
  ) {
    mostPopular: articles(
      locale: $locale
      sort: $sorting
      pagination: $pagination
      filters: $mostPopularFilter
    ) {
      data {
        attributes {
          title
          slug
          category {
            data {
              attributes {
                name
                slug
              }
            }
          }
          cover {
            data {
              attributes {
                alternativeText
                url
                caption
              }
            }
          }
          publishedAt
        }
      }
    }
    lastRead: articles(
      locale: $locale
      sort: $sorting
      pagination: $pagination
      filters: $lastReadFilter
    ) {
      data {
        attributes {
          title
          slug
          category {
            data {
              attributes {
                name
                slug
              }
            }
          }
          cover {
            data {
              attributes {
                alternativeText
                url
                caption
              }
            }
          }
          publishedAt
        }
      }
    }
    categories(locale: $locale) {
      data {
        attributes {
          name
          slug
          cover {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`

export const GET_NEXT_AND_PREVIOUS_POST = gql`
  query NextPreviousPost(
    $locale: I18NLocaleCode
    $publishedAt: DateTime!
    $categorySlug: StringFilterInput!
  ) {
    nextArticle: articles(
      filters: {
        category: { slug: $categorySlug }
        publishedAt: { gt: $publishedAt }
      }
      locale: $locale
      sort: "publishedAt:asc"
      pagination: { limit: 1 }
    ) {
      data {
        attributes {
          slug
        }
      }
    }
    previousArticle: articles(
      filters: {
        category: { slug: $categorySlug }
        publishedAt: { lt: $publishedAt }
      }
      locale: $locale
      sort: "publishedAt:desc"
      pagination: { limit: 1 }
    ) {
      data {
        attributes {
          slug
        }
      }
    }
  }
`

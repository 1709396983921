import { gql } from "@apollo/client"

export const TableFragment = gql`
  fragment TableFragment on ComponentSectionsTable {
    tableVariant: variant
    type
    caption
    ariaLabel
    header {
      label
    }
    body {
      cells {
        label
        icon {
          variant
          icon
        }
      }
    }
  }
`

import { IS_PRODUCTION } from "@/utils/enviroment"

export function getStrapiURL(path = "") {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost"}${path}`
}

export type SignedUrlApiResponse = {
  status: number
  message?: string
  res?: string
}

export type SignedUrlResponse = {
  url: string | null
  err?: string
}

export function resolveMediaUrl(path?: string | null) {
  if (!path) {
    return null
  }
  // Return the full URL if the media is hosted on an external provider
  if (path.startsWith("http") || path.startsWith("//") || IS_PRODUCTION) {
    return path
  }
  // Otherwise prepend the URL path with the Strapi URL
  return `http://localhost:1337${path}`
}

export function formatDate(dateString: string) {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  return date.toLocaleDateString("en-US", options)
}

// ADDS DELAY TO SIMULATE SLOW API REMOVE FOR PRODUCTION
export const delay = (time: number) =>
  new Promise((resolve) => setTimeout(() => resolve(1), time))
